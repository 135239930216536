body {
    background-color: #fff;
}

a {
    text-decoration: none;
    color: #333;
    -webkit-transition: all 300ms ease-in-out;
    -moz-transition: all 300ms ease-in-out;
    -ms-transition: all 300ms ease-in-out;
    -o-transition: all 300ms ease-in-out;
    transition: all 300ms ease-in-out;
}

a:visited, a:active {
    text-decoration: none;
    color: #000;
}

a:hover {
    text-decoration: none;
    color: #000;
}

.headerBase {
    background-color: #ff3044;
    padding-top: 10px;
    padding-bottom: 10px;
    border-bottom: 2px solid #333;
    margin-bottom: 5px;
}

nav ul {
    list-style:  none;
    display: inline-block;
    padding: 0px;
    margin: 0px;
}

nav ul li {
    list-style: none;
    display: inline-block;
    padding: 0px;
    margin: 0 10px;
}

nav ul li a, nav ul li a:visited, nav ul li a:active {
    color: #FFF;
    display: block;
    padding: 2px 8px;
    -webkit-border-radius: 4px;
    -moz-border-radius: 4px;
    border-radius: 4px;
}

nav ul li a:hover {
    background-color: #333;
    font-weight: normal;
}

nav ul li a.logoutBut {
    display: inline-block;
}

nav ul li a.logoutBut:hover  {
    background: none;
}

.ListProjectLi a:hover {
    font-weight: bold;
}

.adminWheel {
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    background-color: #bd2130;
    color: #FFF;
    font-weight: bold;
    display: inline-block;
    position: relative;
}

.absoluteCenter {
    display: flex;
    justify-content: center;
    align-items: center;

    /* position the div in center */
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

footer {
    background-color: #ff3044;
    padding-top: 5px;
    padding-bottom: 5px;
    border-top: 2px solid #333;
    margin-top: 5px;
}

